import { useNavigate } from "@remix-run/react";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect } from "react";
import { Launchpads } from "~/components/Launchpads";
import { Provider } from "~/constants";
import { emptyAudioPlayer } from "~/libs/audio-player";
import { idb } from "~/libs/idb";

export default function Index() {
  const metadata = useLiveQuery(() => idb.metadata.limit(1).first());

  const navigate = useNavigate();

  useEffect(() => {
    if (metadata && metadata.provider === Provider.GoogleDrive) {
      navigate("/googledrive/" + metadata.publicId);
    }
  }, [metadata, navigate]);

  return <Launchpads player={emptyAudioPlayer} />;
}
